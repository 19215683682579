import { useState } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Link,
  Text,
  VStack,
  HStack,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import GooglePlayImage from "../assets/google_play.png";

export default function Component() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [placement] = useState("right");

  const navItems = [
    {
      label: "Privacy Policy",
      href: "privacy-policy",
    },
    {
      label: "Terms & Conditions",
      href: "terms-and-conditions",
    },
    {
      label: "Cancellation & Refund Policy",
      href: "cancellation-refund-policy",
    },
    {
      label: "Contact Us",
      href: "contact-us",
    },
  ];

  return (
    <Box minHeight="100vh" display="flex" flexDirection="column">
      <Box as="header" borderBottom="1px" borderColor="gray.200" py={4}>
        <Container maxW="container.xl">
          <Flex justifyContent="space-between" alignItems="center">
            <Flex alignItems="center">
              <Box
                bg="black"
                w={8}
                h={8}
                borderRadius="full"
                display="flex"
                alignItems="center"
                justifyContent="center"
                mr={2}
              >
                <Text color="white" fontWeight="bold" fontSize="xl">
                  C
                </Text>
              </Box>
              <Heading as="h1" size="lg">
                Callee
              </Heading>
            </Flex>
            <HStack spacing={4} display={{ base: "none", md: "flex" }}>
              {navItems.map((item) => (
                <Link
                  key={item.href}
                  href={item.href}
                  _hover={{ color: "gray.600" }}
                  fontSize={14}
                >
                  {item.label}
                </Link>
              ))}
            </HStack>
            <Button
              display={{ base: "flex", md: "none" }}
              onClick={onOpen}
              variant="outline"
            >
              <HamburgerIcon />
            </Button>
          </Flex>
        </Container>
      </Box>

      <Drawer onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px" fontWeight="bold">
            Menu
          </DrawerHeader>
          <DrawerBody>
            <VStack spacing={4} align="stretch">
              {navItems.map((item) => (
                <Link key={item.href} href={item.href} onClick={onClose}>
                  {item.label}
                </Link>
              ))}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Box
        as="main"
        flex={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Container maxW="container.md" textAlign="center">
          <VStack spacing={6}>
            <Heading as="h2" size="4xl" color="gray.900">
              Callee
            </Heading>
            <Heading as="h3" size="xl" color="green.600">
              Money Collection Made Easy
            </Heading>
            <Text color="gray.600" fontSize="lg">
              Callee is a platform designed to simplify the collection of
              payments from multiple individuals. It is particularly useful for
              administrators, organizers, or community leaders who need to
              collect funds for events, group activities, or community
              contributions.
            </Text>
            <Text color="red" fontWeight="bold">
              Mobile apps will be live soon
            </Text>
            {/* <Link href="https://callee.app" isExternal margin="auto">
              <Image
                src={GooglePlayImage}
                height="50px"
                objectFit="contain"
                alt="Get it on Google Play"
              />
            </Link> */}
          </VStack>
        </Container>
      </Box>

      <Box
        as="footer"
        borderTop="1px"
        borderColor="gray.200"
        py={4}
        textAlign="center"
      >
        <Text color="gray.500">&copy; 2024 Callee. All rights reserved.</Text>
      </Box>
    </Box>
  );
}
